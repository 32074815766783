import React from "react";
import styled from "@emotion/styled";
import { colors, fontColorLight, pageGutterLarge } from "../styles/common";
import { BossSvg } from "../svg/boss";
import { extProfileLinks } from "../constants/external-profile-links";

const Container = styled.header`
  padding: ${pageGutterLarge};
  background-color: ${colors.bluish};
  background: linear-gradient(${colors.bluish}, transparent),
    linear-gradient(to top left, #056bf0, transparent),
    linear-gradient(to top right, #033e8c, transparent);
  background-blend-mode: screen;
  color: ${fontColorLight};
  border-right: solid 10px rgba(0, 0, 0, 0.05);

  @media only screen and (max-width: 980px) {
    border-right: none;
    border-bottom: solid 10px rgba(0, 0, 0, 0.05);
  }
`;

const Sticky = styled.div`
  position: sticky;
  top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: end;

  @media only screen and (max-width: 980px) {
    position: relative;
    width: auto;
    align-items: center;
    text-align: center;
  }
`;

const Boss = styled.div`
  > svg {
    width: 10rem;
    height: 10rem;
    padding-top: 0.5rem;
    margin-bottom: 1rem;
    border-radius: 100% 100% 2.5rem;
    border: solid 0.25rem ${colors.whitish};
    background-color: ${colors.brown2};
    overflow: hidden;
    transform: rotate(-1deg);

    @media only screen and (max-width: 980px) {
      border-radius: 100%;
    }
  }
`;

const H3 = styled.h3`
  color: white;
  line-height: 1.75rem;
  margin: 1.5rem 0;
`;

const H4 = styled.h4`
  line-height: 1.75rem;
  margin: 1.5rem 0;
`;

const ExternalProfiles = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;

  @media only screen and (max-width: 980px) {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  padding: 0.5rem 0.5rem;
  margin: 0 0.25rem;
  transition: all 0.25s linear;
  border: solid 1px transparent;

  &:hover {
    color: rgba(255, 255, 255, 1);
    border: solid 1px rgba(255, 255, 255, 0.5);
  }

  > svg {
    padding-left: 0.5rem;
    margin-left: auto;
  }
`;

export default class Hero extends React.PureComponent {
  render() {
    return (
      <Container>
        <Sticky>
          <Boss>
            <BossSvg />
          </Boss>

          <H3>
            <strong>Hello, my name is Kurt.</strong>
          </H3>

          <H4>
            I am passionate about building great user experiences and fast web
            applications.
          </H4>

          <ExternalProfiles>
            {extProfileLinks.map(({ href, title, icon: Icon }) => (
              <Link key={title} href={href} target="_blank" rel="noopener">
                <span>{title}</span>
                <Icon />
              </Link>
            ))}
          </ExternalProfiles>
        </Sticky>
      </Container>
    );
  }
}
