import { AiOutlineLinkedin } from "react-icons/ai";
import { FiGithub } from "react-icons/fi";
import { SiCodewars, SiPluralsight } from "react-icons/si";

export const extProfileLinks = [
  {
    href: "https://www.linkedin.com/in/kurt-s/",
    title: "LinkedIn",
    icon: AiOutlineLinkedin,
  },
  {
    href: "https://github.com/benji343",
    title: "GitHub: benji343",
    icon: FiGithub,
  },
  {
    href: "https://github.com/kurt343",
    title: "GitHub: kurt343",
    icon: FiGithub,
  },
  {
    href: "https://www.codewars.com/users/kurt343",
    title: "CodeWars",
    icon: SiCodewars,
  },
  {
    href: "https://app.pluralsight.com/profile/kurt343",
    title: "Pluralsight",
    icon: SiPluralsight,
  },
];
