import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import "../styles/defaults.css";

import {
  colors,
  fontColorDark,
  pageGutterSmall,
  pageGutterLarge,
} from "../styles/common";

import Hero from "../containers/hero";

const Page = styled.div`
  height: 100vh;
  width: 100vw;
  max-width: 1920px;
  margin: 0 auto;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 980px) {
    flex-direction: column;
  }
`;

const Content = styled.main`
  display: flex;
  flex-direction: column;
  padding: ${pageGutterLarge};
  padding-right: 10rem;
  flex: 2 1 auto;
  background-color: ${colors.whitish};
  color: ${fontColorDark};
  border-right: solid 10px rgba(0, 0, 0, 0.2);

  @media only screen and (max-width: 980px) {
    width: auto;
    padding: ${pageGutterSmall};
  }
`;

const Layout = ({ children }) => {
  return (
    <Page>
      <Container>
        <Hero>test</Hero>
        <Content>{children}</Content>
      </Container>
    </Page>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
