export const colors = {
  darkgrey: "#444359",
  bluish: "#034AA6",
  lightBlue: "#559CAD",
  whitish: "#F9F4F5",
  brown1: "#A68051",
  brown2: "#A67C6D",
  xlightBlue: "#E0E6ED",
  typescriptBlue: "#3178c6",
  javascriptYellow: "#f7df1e",
  reactBlue: "#00d8ff",
  reduxPurple: "#764abc",
  nodeGreen: "#6cc24a",
  htmlOrange: "#e34f26",
  css3Blue: "dodgerblue", // TODO: get the real thing
  npmBlood: "#cb3837",
  gitOrange: "#fc6d26",
  awsYellow: "#ff9900",
  postgresBlue: "#336791",
  pythonBlue: "#4584b6",
  gopherBlue: "#00ADD8",
  emberRed: "#f23819",
  coffeescript: "purple",
  dotnetBlue: "blue",
  javaRed: "red",
  mysqlBlue: "#00758f",
  mongodbGreen: "#589636",
  gatsbyPurple: "#639",
  swiftOrange: "#F05138",
  vueGreen: "#42b883",
  xstateBlack: "#000",
};

export const fontColorDark = "rgba(68, 67, 89, 0.85)";
export const fontColorLight = "rgba(223, 231, 242, 0.85)";

export const fontWeightBold = 600;
export const fontWeightMed = 400;
export const fontWeightLight = 200;

export const pageGutterLarge = "5rem";
export const pageGutterSmall = "10%";
